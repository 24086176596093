<template>
  <v-container id="page-edrColleage" fluid>
    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :headers="fillableHeaders"
      :title="$t('model.edr_colleage.edr_colleage')"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <edr-colleage-form
      v-model="entity"
      :open="openNewForm"
      :processing="isProcessing"
      :edit-mode="editMode"
      @close="openNewForm = false"
      @save="onSave"
    />
    <wee-confirm ref="weeConfirmRef" />
    <wee-toast ref="weeToastRef" />
  </v-container>
</template>

<script>
import { getDateWithDefaultFormat } from "@/plugins/dateUtil";
// service
import EdrColleageService from "@/api/EdrColleageService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount } from "@vue/composition-api";
export default {
  name: "PageEdrColleage",
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    EdrColleageForm: () => import("./EdrColleageForm")
  },
  setup(props, { refs, root }) {
    const edrColleageService = new EdrColleageService();
    // column, label, searchable, sortable, fillable, image, status, date, avatar
    const tableHeaders = [
      {
        column: "ipaddress",
        label: "model.edr_colleage.ipaddress",
        searchable: true,
        sortable: true,
        fillable: true
        // linkable: {external: true},
      },
      {
        column: "domain",
        label: "model.edr_colleage.domain",
        searchable: true,
        sortable: true,
        fillable: true
        // linkable: {external: true},
      },
      {
        column: "school_id",
        label: "model.edr_colleage.school_id",
        searchable: true,
        sortable: true,
        fillable: true
        // linkable: {external: true},
      },
      {
        column: "college_code",
        label: "model.edr_colleage.college_code",
        searchable: true,
        sortable: true,
        fillable: true
        // linkable: {external: true},
      },
      {
        column: "name_th",
        label: "model.edr_colleage.name_th",
        searchable: true,
        sortable: true,
        fillable: true
        // linkable: {external: true},
      },
      {
        column: "name_en",
        label: "model.edr_colleage.name_en",
        searchable: true
        // linkable: {external: true},
      },
      {
        column: "java_ipaddress",
        label: "model.edr_colleage.java_ipaddress",
        searchable: true
        // linkable: {external: true},
      },
      {
        column: "php_ipaddress",
        label: "model.edr_colleage.php_ipaddress",
        searchable: true
      },
      {
        column: "sockets_path",
        label: "model.edr_colleage.sockets_path",
        searchable: true
        // linkable: {external: true},
      },
      {
        column: "use_std_api",
        label: "model.edr_colleage.use_std_api",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
        // linkable: {external: true},
      },
      {
        column: "ssl_expire",
        label: "model.edr_colleage.ssl_expire",
        searchable: true,
        sortable: true,
        fillable: true,
        date: true
      },
      {
        column: "status",
        label: "model.edr_colleage.status",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];

    // entity
    const initialItem = {
      id: "",
      ipaddress: "122.155.208.61",
      domain: "master.appedr.com",
      school_id: "0000000000",
      college_code: "master",
      name_th: "",
      name_en: "",
      java_ipaddress: "http://master.appedr.com",
      php_ipaddress: "https://master.appedr.com:446",
      college_site: "http://www.google.com",
      sockets_path: "wss://master.appedr.com:446/_ws_/",
      use_std_api: false,
      ssl_expire: getDateWithDefaultFormat(),
      storage: 0,
      ram: 0,
      cpu: 0,
      sort_order: 1,
      status: false,
      deploy_java: true,
      deploy_php: true,
      production: true,
      created_user: null,
      created_at: null,
      updated_user: null,
      updated_at: null
    };

    const { crud } = useCrudApi(
      refs,
      root,
      edrColleageService,
      initialItem,
      tableHeaders
    );

    // fell free to change sort colunm and mode
    // sort.column = "id";
    // sort.mode = "ASC";

    onBeforeUnmount(() => {});

    return {
      ...toRefs(crud)
    };
  }
};
</script>
